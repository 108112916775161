// composables/useNewsletterSubscribe.ts
import { NewsletterSubscription, NewsletterUnsubscription } from '@/lib/types/Newsletter';
import { ref } from 'vue';

export function useNewsletter() {
  const success = ref<boolean>(false);

  const subscribe = async (subscribeData: NewsletterSubscription) => {
    success.value = false;
    try {
      const response = await fetch('/api/newsletter/subscribe', {
        method: 'POST',
        body: JSON.stringify(subscribeData),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to subscribe');
      }
      success.value = true;
    } catch (err) {
      console.error('Error sending subscribe:', err);
    }
  };

  const unsubscribe = async (unsubscribeData: NewsletterUnsubscription) => {
    success.value = false;

    try {
      const response = await fetch('/api/newsletter/unsubscribe', {
        method: 'POST',
        body: JSON.stringify(unsubscribeData),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to send unsubscribe');
      }

      success.value = true;
    } catch (err) {
      console.error('Error sending unsubscribe:', err);
    }
  };

  return {
    success,
    unsubscribe,
    subscribe
  };
}
